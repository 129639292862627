<template>
    <ToastNotification message="Email copied successfully" :isVisible="isVisible" bgColor="green" />
    <!-- <CandidateActivity :showActivity="showActivity" :toggleActivity="toggleActivity" :candidate="candidate" :candidateInfo="candidateInfo" /> -->
    <!-- <div v-if="showMenu" @click="toggleMenu" class="w-[100%] h-[100%] fixed top-0 left-0 right-0 bottom-0" style="z-index: 10; background: red"></div> -->
    <div v-if="showMenu" @click="toggleMenu" class="absolute top-0 left-0 right-0 bottom-0" style="z-index: 10; background: transparent"></div>
    <Transition name="flip">
        <div
            class="flipped relative w-[22rem] h-[32rem] will-change-transform rounded-lg bg-white overflow-hidden flex flex-col items-center justify-start group hover:scale-105 duration-700 transition-all ease-out shadow-card pb-6"
            v-if="!flipped"
        >
            <div
                class="w-full h-1/2 bg-cover bg-center object-fill grayscale group-hover:grayscale-0 overflow-hidden flex justify-center relative"
                :style="{ backgroundImage: 'url(' + getImgUrl(candidateInfo?.Avatar) + ')' }"
            >
                <span class="absolute bg-white rounded w-16 px-1 py-2 shadow-card text-slate-700 text-xl font-semibold text-center left-2 top-2">{{ calculateScore() }}%</span>
                <!-- <img loading="lazy"  decoding="async" class="w-2/3 h-[fit-content] object-fill grayscale group-hover:grayscale-0" :src="getImgUrl(candidateInfo?.Avatar)" alt="candidate-image" /> -->
            </div>
            <h1 class="font-semibold text-xl text-left w-full text-slate-700 px-4 mt-2">
                {{ candidateInfo?.Last_name + " " + candidateInfo?.First_name }}
            </h1>
            <h2 class="text-sm font-thin px-4 text-NeonBlue w-full mt-1">
                {{ candidateInfo?.Email }}
            </h2>
            <p class="px-4 mt-4 mb-2 w-full text-left text-slate-700 font-light text-sm leading-6">{{ candidateInfo?.Bio }}</p>
            <button
                class="h-fit w-fit py-3 px-10 text-center text-lg font-semibold text-white bg-NeonBlue rounded-md mt-auto mx-auto hover:scale-95"
                @click="
                    () => {
                        this.Store.candidateInfoAct = candidateInfo;
                        this.Store.candidateActivity = candidate;
                        this.$router.push({
                            path: '/candidate',
                            query: { email: candidateInfo.Email, projectId: candidate?.projectId },
                        });
                    }
                "
            >
                See report
            </button>
            <button
                title="flip to see more!"
                class="absolute top-2 right-2 w-10 h-10 border bg-white border-slate-300 rounded-full flex justify-center items-center opacity-0 group-hover:opacity-100"
                @click="flipped = !flipped"
            >
                <font-awesome-icon :icon="['fas', 'rotate-right']" />
            </button>
        </div>
        <div
            class="flipped relative w-[23rem] h-[32rem] will-change-transform rounded-lg bg-white overflow-hidden flex flex-col items-start justify-between group hover:scale-105 duration-700 transition-all ease-out shadow-card pt-10 pb-6"
            v-else
        >
            <h1 class="font-bold text-3xl text-center w-full text-slate-700 px-3 mt-2">
                {{ candidateInfo?.Last_name + " " + candidateInfo?.First_name }}
            </h1>
            <div class="w-full flex flex-row justify-center items-center gap-4 mt-4 mb-8">
                <a class="w-10 h-10 rounded-full border border-slate-300 bg-white text-slate-700 font-light flex justify-center items-center" href="#" @click="copyEmail"
                    ><font-awesome-icon :icon="['fas', 'envelope']"
                /></a>
                <a
                    class="w-10 h-10 rounded-full border border-slate-300 bg-white text-slate-700 font-light flex justify-center items-center"
                    :href="candidateInfo?.linkedIn ? `${candidateInfo?.linkedIn}` : '#'"
                    ><font-awesome-icon :icon="['fab', 'linkedin']"
                /></a>
                <a
                    class="w-10 h-10 rounded-full border border-slate-300 bg-white text-slate-700 font-light flex justify-center items-center"
                    :href="candidateInfo?.Github ? `${candidateInfo?.Github}` : '#'"
                    ><font-awesome-icon :icon="['fab', 'github']"
                /></a>
                <a
                    class="w-10 h-10 rounded-full border border-slate-300 bg-white text-slate-700 font-light flex justify-center items-center"
                    :href="candidateInfo?.website ? `${candidateInfo?.Github}` : '#'"
                    ><font-awesome-icon :icon="['fas', 'globe']"
                /></a>
            </div>
            <ul class="list-none w-full px-8">
                <li class="flex flex-row justify-start items-center gap-5 w-full my-4">
                    <font-awesome-icon :icon="['fas', 'ranking-star']" class="text-NeonBlue w-5 h-5" />
                    <h2 class="text-base font-light text-slate-700 text-left">
                        {{ calculateScore() ? calculateScore() + "%" : "N/A" }}
                    </h2>
                </li>
                <li class="flex flex-row justify-start items-center gap-5 w-full my-4">
                    <font-awesome-icon :icon="['fas', 'phone']" class="text-NeonBlue w-5 h-5" />
                    <h2 class="text-base font-light text-slate-700 text-left">
                        {{ candidateInfo?.Phone ? candidateInfo?.Phone : "N/A" }}
                    </h2>
                </li>
                <li class="flex flex-row justify-start items-center gap-5 w-full my-4">
                    <font-awesome-icon :icon="['fas', 'clipboard-check']" class="text-NeonBlue w-5 h-5" />
                    <h2 class="text-base font-light text-slate-700 text-left">
                        {{ candidate?.status ? candidate?.status : "N/A" }}
                    </h2>
                </li>
                <li class="flex flex-row justify-start items-center gap-5 w-full my-4">
                    <font-awesome-icon :icon="['fas', 'folder-closed']" class="text-NeonBlue w-5 h-5" />
                    <h2 class="text-base font-light text-slate-700 text-left">
                        {{ getProjectName(candidate?.projectId) }}
                    </h2>
                </li>
                <li class="flex flex-row justify-start items-center gap-5 w-full my-4">
                    <font-awesome-icon :icon="['fas', 'calendar-day']" class="text-NeonBlue w-5 h-5" />
                    <h2 class="text-base font-light text-slate-700 text-left">
                        {{ getDateAndTime(candidate?.createdAt) }}
                    </h2>
                </li>
            </ul>
            <div class="px-4 w-full flex flex-row justify-between items-center mt-auto gap-4">
                <button class="w-1/2 rounded-md h-fit py-3 text-base font-normal text-center hover:scale-95 text-slate-700 bg-white border border-slate-300" @click="archeiveCandidate">
                    Archeive
                </button>
                <button
                    class="w-1/2 rounded-md h-fit py-3 text-base font-normal text-center hover:scale-95 text-white bg-NeonBlue"
                    @click="
                        () => {
                            this.Store.candidateInfoAct = candidateInfo;
                            this.Store.candidateActivity = candidate;
                            this.$router.push({
                                path: '/candidate',
                                query: { email: candidateInfo.Email, projectId: candidate?.projectId },
                            });
                        }
                    "
                >
                    See report
                </button>
            </div>
            <button
                title="flip to see more!"
                class="absolute top-2 left-2 w-10 h-10 border border-slate-300 rounded-full flex justify-center items-center opacity-0 group-hover:opacity-100"
                @click="flipped = !flipped"
            >
                <font-awesome-icon :icon="['fas', 'rotate-left']" />
            </button>
        </div>
    </Transition>
</template>

<script>
// import CandidateActivity from "@/views/ActivityView.vue";
// import CandidateActivity from "@/components/CandidateActivity.vue";
import ToastNotification from "./ToastNotification.vue";
// import CandidateMenu from "@/components/CandidateMenu.vue";
import { useStore } from "../store/index";
import axios from "axios";
// import truncate from "@/helpers/truncateText";
export default {
    name: "CandidateCard",
    components: {
        // CandidateActivity,
        ToastNotification,
        // CandidateMenu,
    },
    props: ["candidate", "candidateInfo", "passed", "backgroundHeader", "getCandidates"],
    data() {
        return {
            showActivity: false,
            isVisible: false,
            showMenu: false,
            flipped: false,
        };
    },
    computed: {
        bioTest() {
            return "Lorem ipsum dolor sit amet consectetur adipisicing elit. In ex, consequatur beatae accusamus quis officiis obcaecati unde, commodi iusto magni tempora qui? Placeat nam asperiores architecto provident ea necessitatibus totam?";
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        calculateScore() {
            let score = 0;
            // let resultsCalc = this.candidate?.results?.filter((element) => element?.totalPoints !== undefined);

            this.candidate?.results?.forEach((element) => {
                if (element.rangesPoint || element.totalPoints) {
                    score += element.rangesPoint ? (element.rangesPoint * 100) / (element.quesionsNbr * 5) : (element.totalPoints * 100) / element.quesionsNbr;
                }
            });
            const averageScore = score / this.candidate?.results?.length;
            const roundedScore = averageScore.toFixed();
            return roundedScore;
        },

        copyEmail() {
            const emailText = this.candidateInfo.Email;
            const el = document.createElement("textarea");
            el.value = emailText;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            this.isVisible = true;
            setTimeout(() => {
                this.isVisible = false;
            }, 5000);
        },
        toggleActivity() {
            this.showActivity = !this.showActivity;
        },
        getDateAndTime(dateString) {
            const date = new Date(dateString);

            const year = date.getUTCFullYear();
            const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
            const day = date.getUTCDate().toString().padStart(2, "0");
            const hours = date.getUTCHours().toString().padStart(2, "0");
            const minutes = date.getUTCMinutes().toString().padStart(2, "0");
            const seconds = date.getUTCSeconds().toString().padStart(2, "0");

            const formattedDateTime = `${year}-${month}-${day} ${parseInt(hours) + 1}:${minutes}:${seconds}`;
            return formattedDateTime;
        },
        getImgUrl(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                // console.log({ image });
                return image;
            }
            return require("../assets/Images/candidate-image.png");
        },
        getProjectName(projectId) {
            const project = this.Store.projects.find((project) => project._id === projectId);
            return project?.name;
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        archeiveCandidate() {
            console.log("archeiving");
            if (this.candidate?.status === "archived") {
                this.toggleLoading();
            }
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/candidates/switchStatus/${this.candidate._id}`,
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            };

            axios
                .request(config)
                .then(async (response) => {
                    if (this.candidate?.status === "active") {
                        await this.getCandidates();
                        console.log("candidate archeived");
                    } else {
                        await this.Store.getArchivedCandidates().then(() => {
                            this.toggleLoading();
                            console.log("candidate activated");
                        });
                    }
                    console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped lang="scss">
.flipped {
    transition: transform 1s;
    transform-style: preserve-3d;
}

.flip-enter-active {
    transition: transform 1s ease;
}

.flip-leave-active {
    display: none;
}

.flip-enter-from,
.flip-leave-to {
    transform: rotateY(180deg);
    opacity: 0;
}

.flip-leave-from {
    display: none;
    opacity: 0;
}

.header-background-1 {
    background: linear-gradient(135deg, #868cff 0%, #4318ff 100%, #2196f3 100%);
}

.header-background-2 {
    background: linear-gradient(135deg, #ffd133 0%, #4318ff 100%, #2196f3 100%);
}

.header-background-0 {
    background: linear-gradient(135deg, #00848b 7.08%, #067a96 35.73%, #4318ff 100%, #2196f3 100%);
}

.grid-row-info {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;
    margin-bottom: 16px;
}
</style>
