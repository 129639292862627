<template>
    <section class="flex flex-col justify-between items-center px-4 lg:px-12 py-6 lg:py-12 valueSection">
        <h1 class="text-3xl lg:text-6xl font-bold text-center text-slate-700 my-10 lg:px-28">More reasons businesses are choosing GO Platform</h1>
        <div class="flex flex-col items-center w-full gap-1">
            <div class="flex justify-center flex-wrap gap-5 w-full">
                <div
                    v-for="card in cards.slice(0, 3)"
                    :key="card.title"
                    class="valueCard h-[30rem] w-[18rem] rounded-lg p-3 pt-10 hover:shadow-lg hover:scale-105 bg-white flex flex-col justify-start items-center m-2"
                >
                    <img loading="lazy" decoding="async" :src="require(`../../assets/Images/${card.img}`)" :alt="card.title" class="w-full h-2/5 mb-8" />
                    <h2 class="text-slate-700 font-semibold text-xl w-full text-center mb-6">{{ card.title }}</h2>
                    <p class="text-slate-700 font-light text-left text-sm w-full px-6">{{ card.text }}</p>
                </div>
            </div>
            <div class="flex justify-center gap-5 w-full">
                <div
                    v-for="card in cards.slice(3, 5)"
                    :key="card.title"
                    class="valueCard h-[30rem] w-[18rem] rounded-lg p-3 pt-10 hover:shadow-lg hover:scale-105 bg-white flex flex-col justify-start items-center m-2"
                >
                    <img loading="lazy" decoding="async" :src="require(`../../assets/Images/${card.img}`)" :alt="card.title" class="w-full h-2/5 mb-8" />
                    <h2 class="text-slate-700 font-semibold text-xl w-full text-center mb-6">{{ card.title }}</h2>
                    <p class="text-slate-700 font-light text-left text-sm w-full px-6">{{ card.text }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
    name: "ValueSection",
    data() {
        return {
            cards: [
                {
                    img: "valueImg1.svg",
                    title: "Secured Platform",
                    text: "Our platform prioritizes the security of your data and assessments, ensuring that your information remains safe and protected from unauthorized access or breaches.",
                },
                {
                    img: "valueImg2.svg",
                    title: "Advanced analytics",
                    text: "Gain deep insights into candidate performance and assessment effectiveness with our advanced analytics tools. Unlock valuable data to optimize your hiring process and make informed decisions.",
                },
                /*  {
                      img: "valueImg3.svg",
                      title: "Powerful automation",
                      text: "Streamline your workflow and save time with powerful automation features. From candidate communication to assessment scheduling, our platform automates repetitive tasks, allowing you to focus on strategic initiatives.",
                  },*/
                {
                    img: "valueImg4.svg",
                    title: "Integrations",
                    text: "Seamlessly integrate our platform with your existing systems and tools for a smooth and cohesive experience. Whether it's your applicant tracking system or HR software, our integrations ensure seamless data flow and enhanced productivity.",
                },
                {
                    img: "valueImg5.svg",
                    title: "All-in-one platform",
                    text: "Experience the convenience of having all your assessment needs met in one comprehensive platform. From test creation to candidate evaluation, our all-in-one platform simplifies the entire hiring process.",
                },
                {
                    img: "valueImg6.svg",
                    title: "Advanced charts",
                    text: "Visualize assessment data like never before with our advanced charts. From bar graphs to pie charts, our platform offers a variety of visualization options to help you interpret data and communicate insights effectively.",
                },
            ],
        };
    },
    mounted() {
        // gsap.from(".valueCard",{
        //     scale:0,
        //     duration:2,
        //     stagger:0.4,
        //     ease:"ease",
        //     ScrollTrigger:{
        //         trigger:'.valueSection',
        //         start: "top 60%",
        //         toggleActions: "play none none reset",
        //         markers:{
        //             startColor:"purple",
        //             endColor: "green", fontSize: "18px", fontWeight: "bold", indent: 20
        //         }
        //     }
        // })
        gsap.utils.toArray(".valueCard").forEach((letter, i) => {
            gsap.from(letter, {
                scale: 0,
                duration: 2,
                stagger: 0.4,
                ease: "ease",
                delay: i,
                scrollTrigger: {
                    trigger: ".valueSection",
                    start: "top 70%",
                    toggleActions: "play none none reset",
                },
            });
        });
    },
};
</script>

<style lang="scss" scoped></style>
