<template>
    <div class="content" v-show="title == selectedTitle">
        <slot></slot>
    </div>
</template>

<script>
import { inject } from "vue";

export default {
    name: "ProfileStep",
    props: ["title"],
    setup() {
        const selectedTitle = inject("selectedTitle");
        return {
            selectedTitle,
        };
    },
};
</script>

<style lang="scss" scoped>
.content {
    font-family: "Roboto";
    font-style: normal;
    padding: 2%;
    color: #4d5e80;
}
</style>
