<template>
    <div class="w-[80%] upgradePlan">
        <img loading="lazy" decoding="async" src="../assets/Images/icons/lock-icon.svg" alt="upgrade-icon" />
        <div>
            <h2>Unlock all 298+ Assessment tests from this Search.</h2>
            <button @click="this.Store.toggleSubscriptions()">Upgrade Plan</button>
        </div>
    </div>
</template>

<script>
import { useStore } from "../store/index.js";
export default {
    name: "upgradePlan",

    setup() {
        const Store = useStore();
        return { Store };
    },
};
</script>

<style scoped lang="scss">
.upgradePlan {
    display: grid;
    grid-template-columns: 1fr 5fr;
    border-radius: 35px;
    background: #2196f3;
    padding: 1% 3%;
    margin: 5% 10%;
    width: 80%;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);

    img {
        width: 35px;
        height: 35px;
    }
    > :nth-child(2) {
        display: flex;
        justify-content: center;
        h2 {
            margin-left: -5%;
            color: #fff;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 42px; /* 175% */
            letter-spacing: -0.48px;
        }
        button {
            display: inline-flex;
            padding: 7.822px 32.156px 8.178px 33.244px;
            justify-content: center;
            align-items: center;
            border-radius: 22px;
            background: #fff;
            margin-left: 2%;
            color: #0f172a;
            font-family: Roboto;
            font-size: 20.467px;
            font-style: normal;
            font-weight: 700;
            line-height: 27.378px; /* 116.667% */
            letter-spacing: -0.469px;
            transition: all 0.4s ease-in-out;
            &:hover {
                font-size: 22px;
                // background: #0f172a;
                // color: #fff;
            }
        }
    }
}
</style>
