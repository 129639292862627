<template>
    <div class="relative w-full px-[5%] lg:px-[8%] py-[3%] overflow-hidden rounded-b-[200px]">
        <div class="absolute top-0 left-0 w-full h-full bg-[#F5F8FF] -z-20 rounded-b-[100px]"></div>

        <h1 class="uppercase font-[700] text-[46px] text-[#333333]">
            What can we
            <span class="relative w-[fit-content] inline-block">
                <span class="absolute top-0 left-0 w-full h-full bg-[#3361FF] -rotate-[6deg] -z-10"></span>
                <span class="relative z-5 text-[#fff]">Do?</span>
            </span>
        </h1>
        <h1 class="text-[20px] font-[600] my-5">GO PLATFORM delivers tailored solutions across the tech spectrum</h1>
        <div class="flex flex-col items-center md:grid md:grid-cols-3 gap-8 mt-[5%]">
            <div class="rounded-l bg-[#fff] flex flex-col items-center py-4 h-[500px] shadow-card" v-for="(data, index) in whatWeDo" :key="index">
                <svg class="my-[5%]" width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="76" height="76" rx="10" fill="url(#paint0_linear_5898_27738)" />
                    <path
                        d="M20 51.7137C25.6807 51.7137 30.2857 46.9167 30.2857 40.9995C30.2857 35.0821 25.6807 30.2852 20 30.2852V37.7851C21.7042 37.7851 23.0857 39.2242 23.0857 40.9995C23.0857 42.7746 21.7042 44.2137 20 44.2137V51.7137Z"
                        fill="#D4FF5B"
                    />
                    <path
                        d="M55.9994 34.6063C54.1162 34.0273 52.1108 33.7148 50.03 33.7148C39.1252 33.7148 30.2852 42.2957 30.2852 52.8805C30.2852 53.943 30.3742 54.9852 30.5455 56.0006H42.8136C42.3757 55.0475 42.1322 53.9917 42.1322 52.8805C42.1322 48.6466 45.6681 45.2143 50.03 45.2143C52.4141 45.2143 54.5513 46.2396 55.9994 47.8606V34.6063Z"
                        fill="#F6FFDE"
                    />
                    <path
                        d="M21.7148 20C22.5967 28.1963 29.3598 34.5714 37.572 34.5714C45.7842 34.5714 52.5473 28.1963 53.4291 20H42.0193C41.3149 21.8143 39.5891 23.0964 37.572 23.0964C35.5548 23.0964 33.8292 21.8143 33.1247 20H21.7148Z"
                        fill="#D4FF5B"
                    />
                    <defs>
                        <linearGradient id="paint0_linear_5898_27738" x1="0" y1="38" x2="76" y2="38" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#3789FF" />
                            <stop offset="1" stop-color="#312F92" />
                        </linearGradient>
                    </defs>
                </svg>
                <h1 class="text-[#3789FF] text-[24px] font-[700] my-5">
                    {{ data.title }}
                </h1>
                <div class="bg-[#F5F8FF] py-6 w-full flex items-center justify-center h-[120px]">
                    <p class="text-[14px] text-[#343637] w-[75%] font-[600]">
                        {{ data.question }}
                    </p>
                </div>
                <div class="py-6 w-[75%] h-[150px] flex items-center justify-center">
                    <p class="text-[14px] text-[#343637]">{{ data.text }}</p>
                </div>
                <div class="my-5">
                    <router-link to="/request-service" class="rounded-[10px] text-[#fff] bg-[#3361FF] px-4 py-2 border border-[#3361FF] hover:bg-transparent hover:text-[#3361FF] duration-300"
                        >Get Started</router-link
                    >
                </div>
            </div>
        </div>
        <h1 class="text-[20px] text-[#343637] my-[8%]">Build your dream team with GO PLATFORM in 72 hours!</h1>
    </div>
</template>

<script>
export default {
    name: "WhatWeDo",
    data() {
        return {
            whatWeDo: [
                {
                    title: "MVP",
                    question: "Looking to develop a new product?",
                    text: "Find high-caliber AI professionals and agile software developers to convert ideas from concept to reality - faster and cheaper than your competition.",
                },
                {
                    title: "Startups",
                    question: "Looking to save costs while scaling swiftly?",
                    text: "Employ remote-savvy tech talent at lowcosts to maximize budget efficiency and supercharge  growth trajectory.",
                },
                {
                    title: "Enterprise",
                    question: "Looking to expand or elevate your tech division?",
                    text: "Find seasoned software and AI experts who are ready to tackle tech challenges of any magnitude from complex integrations to large-scale transformations.",
                },
            ],
            icon: "",
        };
    },
};
</script>
