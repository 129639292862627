<template>
    <nav class="flex items-center bg-white" :style="{ width: '929px', height: '36px' }">
        <div
            v-for="(item, index) in items"
            :key="index"
            @click="setActive(index)"
            :class="[
                'flex justify-center items-center cursor-pointer',
                'w-fit h-[36px] mx-[6px] text-center font-manrope border-b-1 rounded-none',
                activeIndex === index ? 'text-blue-500 border-b-2 rounded-none border-blue-500' : 'text-[#4C535F]',
            ]"
            style="border-radius: 8px"
        >
            {{ item }}
        </div>
    </nav>
    <hr class="h-[3px] bg-[#F5F8FF] w-full" />
</template>

<script>
export default {
    data() {
        return {
            items: ["Skills", "Experience", "Education", "Certification & Award", "Preferences", "Account Setting"],
            activeIndex: null,
        };
    },
    methods: {
        setActive(index) {
            this.activeIndex = index;
        },
    },
};
</script>

<style scoped>
/* Custom styles can be added here */
</style>
