<template>
    <div id="screenshot" class="container" @mousemove="move" @mousedown="mousedown">
        <div class="overlay" :class="{ highlighting: isMouseDown }" :style="{ borderWidth: borderWidth }"></div>
        <div class="crosshairs" :class="{ hidden: isDraggingMouse }" :style="{ left: crossHairsLeft + 'px', top: crossHairsTop + 'px' }"></div>
    </div>
</template>

<script>
export default {
    name: "ScreenShot",
    data() {
        return {
            crossHairsLeft: 0,
            crossHairsTop: 0,
            startX: 0,
            startY: 0,
            isMouseDown: false,
            isDraggingMouse: false,
        };
    },
    methods: {
        move(event) {
            var startY = this.startY,
                startX = this.startX,
                endX = event.clientX,
                endY = event.clientY;
            // windowWidth  = window.innerWidth,
            // windowHeight = window.innerHeight;
            this.crossHairsTop = event.clientY;
            this.crossHairsLeft = event.clientX;
            if (this.isMouseDown) {
                if (endX >= startX && endY >= startY) {
                    this.isDraggingMouse = true;
                }
            }
        },
        mousedown(event) {
            this.startX = event.clientX;
            this.startY = event.clientY;
            this.isMouseDown = true;
        },
    },
};
</script>

<style lang="scss" scoped></style>
