<template>
    <div class="tab-content" v-show="type == selectedType">
        <slot></slot>
    </div>
</template>

<script>
import { inject } from "vue";
export default {
    name: "AssessementTab",
    props: ["type"],
    setup() {
        const selectedType = inject("selectedType");

        return {
            selectedType,
        };
    },
    mounted() {
        // console.log("selected test", this.type == this.selectedType);
    },
};
</script>

<style scoped>
.tab-content {
    /* padding: 10% 8%; */
    /* border-top: 1px solid #ddd;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5315px;
    line-height: 16px;
    color: #4D5E80; */
}
</style>
