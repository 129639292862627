<template>
    <section class="flex flex-col justify-between items-center px-4 lg:px-12 py-6 lg:py-12 bg-gradient-to-b from-[#9000F9] via-[#C734B2] to-[#FF6969]">
        <span class="font-light text-base text-center w-fit px-5 py-3 rounded-full bg-[#FF725E] text-white">Testimonial</span>
        <h1 class="text-3xl lg:text-5xl font-bold text-center text-white my-10 lg:px-28">Recruiters worldwide rely in our platform</h1>
        <Carousel class="w-full">
            <Slide v-for="slide in slides" :key="slide.name">
                <div class="flex flex-col justify-between items-center w-ful">
                    <p class="text-base font-light text-white px-10 lg:px-56 my-4">{{ slide.testimonial }}</p>
                    <img loading="lazy" decoding="async" :src="require(`../../assets/Images/${slide.img}`)" :alt="slide.name" class="w-24 h-24 rounded-full object-cover border border-white" />
                    <h2 class="text-lg font-semibold text-white w-fit text-center">{{ slide.name }}</h2>
                    <h3 class="text-sm font-light text-white text-center w-fit">{{ slide.job }}</h3>
                    <div class="w-fit flex flex-row justify-center items-center gap-2">
                        <font-awesome-icon :icon="['fas', 'star']" class="w-5 h-5 text-yellow-300" v-for="(star, index) in slide.star" :key="index + '_' + star" />
                    </div>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
                <Pagination />
            </template>
        </Carousel>
    </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
    name: "TestimonySection",
    components: { Carousel, Slide, Pagination, Navigation },
    data() {
        return {
            slides: [
                {
                    img: "man_happy.jpg",
                    name: "James Toriff",
                    job: "HR Manager",
                    star: 5,
                    testimonial:
                        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage .",
                },
                {
                    img: "man_happy.jpg",
                    name: "Alex Micheals",
                    job: "CEO",
                    star: 3,
                    testimonial:
                        "Fantastic platform! Seamless from start to finish. Real-time results are a game-changer, while instant candidate comparison and in-depth reviews make decision-making a breeze. Highly recommended for streamlined, data-driven hiring!",
                },
                {
                    img: "man_happy.jpg",
                    name: "Bernard Rosseau",
                    job: "Founder & CEO",
                    star: 4,
                    testimonial:
                        "Outstanding tool! It simplifies talent assessment with its user-friendly interface and powerful features. Real-time results keep you informed, while instant candidate comparison and detailed reviews empower confident hiring decisions. A must-have for any organization aiming for efficient, data-driven recruitment.",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.w-full {
    width: 100%;
}
</style>
