<template>
    <transition name="show">
        <div class="sidebar" v-if="open" @mouseleave="$emit('Closenav')">
            <transition-group appear name="fade">
                <a href="#" class="sidebar-element" v-for="(item, index) in barElements" :key="index" :style="{ '--index': index }">
                    <img loading="lazy" decoding="async" :src="require(`@/${item}`)" alt="share" />
                </a>
            </transition-group>
        </div>
    </transition>
</template>

<script>
export default {
    name: "SideBar",
    props: ["open"],
    data() {
        return {
            barElements: [
                "assets/Images/send_icon.svg",
                "assets/Images/instagram.svg",
                "assets/Images/brandico_facebook.svg",
                "assets/Images/akar-icons_twitter-fill.svg",
                "assets/Images/bx_bxl-linkedin.svg",
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.sidebar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    z-index: 100;
    flex-direction: column;
    width: 60px;
    height: fit-content;
    top: 50%;
    .sidebar-element {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        margin-bottom: 8px;
        cursor: pointer;
        color: #fefefe;
        background-color: #f6f7fc;
        border-radius: 50%;

        &:hover {
            background: rgba($color: #000000, $alpha: 0.05);
        }
    }
}
.show {
    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateX(-60px);
    }
    &-enter-active,
    &-leave-active {
        transition: all 500ms;
    }
}
.fade {
    &-enter {
        opacity: 0;
        transform: translateX(-60px);
    }
    &-enter-active {
        transition: all 500ms ease-in-out;
        transition-delay: calc(50ms * var(--index));
    }
}
</style>
