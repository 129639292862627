<template>
    <div class="fixed top-0 left-0 w-full h-full allCandidates flex justify-center items-center" v-if="showAllCandidates">
        <div class="h-[75%] w-[40%] bg-white">
            <div style="margin-top: 5%" class="flex flex-row justify-between">
                <span class="recentapp"> {{ $t("Recent Applicants") }}</span>
                <button class="nav-btn trash-btn" @click="toggleShowAllCandidates">
                    <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/close-icon.svg" alt="close-icon" /> -->
                    <font-awesome-icon :icon="['fas', 'xmark']" class="nav-icon trash-icon" />
                </button>
            </div>
            <div class="recentcandidates">
                <RacentCandidateRow2 v-for="(candidate, index) in candidates" :key="index" :candidate="candidate" />
            </div>
        </div>
    </div>
</template>

<script>
import RacentCandidateRow2 from "../components/RacentCandidateRow copy.vue";

export default {
    name: "allCandidates",
    components: { RacentCandidateRow2 },
    props: {
        candidates: {
            type: Array,
            required: true,
        },
        showAllCandidates: {
            type: Boolean,
            required: true,
        },
        toggleShowAllCandidates: {
            type: Function,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
.allCandidates {
    background-color: rgba(0, 0, 0, 0.363);
    z-index: 10;
    // padding: 0 5%;
    > :first-child {
        border-radius: 20px;
        padding: 1% 2%;
    }
    .recentcandidates {
        background-color: #fff;
        display: grid;
        padding: 5% 0%;
        gap: 10px;
    }
    .trash-icon {
        color: red;
    }

    .trash-btn:hover {
        border: 2px solid red;
    }

    .trash-btn:hover + .trash-icon {
        color: red;
    }

    .nav-btn {
        padding-top: 2px;
    }
}
</style>
