<template>
    <div class="wrapper">
        <ul>
            <li v-for="title in tabTitles" :key="title" :class="{ selected: title == selectedTitle }" @click="selectedTitle = title" class="cursor-pointer">
                <span>{{ title }}</span>
            </li>
        </ul>
        <slot> </slot>
    </div>
</template>

<script>
import { ref, provide } from "vue";

export default {
    name: "PersonalInfos",

    setup(props, { slots }) {
        const tabTitles = ref(slots.default().map((tab) => tab.props.title));
        const selectedTitle = ref(tabTitles.value[0]);

        provide("selectedTitle", selectedTitle);
        return {
            selectedTitle,
            tabTitles,
        };
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    font-family: "Roboto";
    font-style: normal;
    display: flex;
    flex-direction: column;

    ul {
        display: flex;
        list-style: none;
        justify-content: flex-start;
        gap: 2rem;
        padding: 0;

        li {
            color: #82888d;
            padding: 1% 2%;
            width: fit-content;
            text-align: center;
        }
    }
}
.selected {
    background: #2196f3;
    color: #fff !important;
    border-radius: 50px;
}
</style>
