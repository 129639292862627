<template>
    <div class="search_bar" v-show="search" ref="searchBarContainer">
        <div class="shadow-[0_0_4px_2px_rgba(0,0,0,.2)]" ref="searchInput">
            <input type="text" id="Keyword" :placeholder="$t('Search Title or Keyword')" class="key_search" />
            <input type="text" name="" id="location" placeholder="Search Specific Assessements" class="loc_search" />
            <input type="submit" name="search" id="" placeholder="Search" class="searchbtn bxshadow" value="Search" />
        </div>
        <!-- <span class="assessement">786 Assessment For 110 Position to fill.</span> -->
    </div>
</template>

<script>
export default {
    name: "searchBar",
    props: ["search"],
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.search_bar {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-self: center;
    justify-items: center;
    align-items: center;
    position: fixed;
    // top: -5%;
    left: 0;
    right: 0;
    z-index: 10;
    flex-wrap: wrap;
    gap: 50px;
    padding: 0% 0%;
    // background: rgba(241, 241, 241, 0.582);
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    // backdrop-filter: blur(9.9px);
    // -webkit-backdrop-filter: blur(9.9px);
    margin-top: 7rem;
    // box-shadow: 0 0 6px 2px rgba(0,0,0,.2);

    @media (min-width: 768px) and (max-width: 991.98px) {
        margin-top: -1rem;
    }

    > :first-child {
        display: flex;
        width: 65%;
        height: 60px;
        background-color: rgba(255, 255, 255, 0.75);
        border-radius: 50px;
        // padding: 1%;
        padding: 0.5rem 1rem;

        @media (min-width: 768px) and (max-width: 991.98px) {
            width: 95%;
        }

        .key_search {
            border: none;
            box-shadow: 0px 1px 22px 1px rgba(69, 64, 219, 0.04);
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
            height: 100%;
            width: 100%;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: #878787;
            flex: 2;
            background-color: rgba(255, 255, 255, 0);

            @media (max-width: 767.98px) {
                /* Your CSS styles for smartphones go here */
                font-size: 12px;
            }
        }

        .loc_search {
            border: none;
            height: 100%;
            width: 100%;
            border-left: 2px solid #878787;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: #878787;
            flex: 2;
            background-color: rgba(255, 255, 255, 0);

            @media (max-width: 767.98px) {
                /* Your CSS styles for smartphones go here */
                font-size: 12px;
            }
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            flex-wrap: nowrap;
            width: 90%;
            padding: 2%;
        }
    }

    .searchbtn {
        background: #2196f3;
        border-radius: 20px;
        height: 100%;
        width: 20%;
        // padding: 1% 0;
        border: none;
        text-align: center;
        color: #fff;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        flex: 1;

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            width: 40%;
            padding: 3% 2%;
            font-size: 16px;
        }
    }

    .assessement {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        color: #343637;
        margin: 0%;

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            font-size: 24px;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        position: fixed;
        top: 15%;
        width: 100%;
        z-index: 20;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        margin-top: 20%;
        gap: 20px;
    }
}
input {
    outline: none;
}
</style>
