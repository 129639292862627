<template>
    <div class="tabs">
        <div :class="{ 'fixed-tabs': isFixed }" ref="tabsContainer" :style="{ left: this.$route.path === '/NewAssessement' && '12%' }">
            <ul class="tabs__header flex-col md:flex-row">
                <li v-for="title in tabTypes" :key="title" :class="{ selected: title == selectedType }" @click="selectedType = title">
                    <span>{{ title }}</span>
                </li>
            </ul>
        </div>
        <slot> </slot>
    </div>
</template>

<script>
import { ref, provide } from "vue";
export default {
    name: "AssessementTypes",
    setup(props, { slots }) {
        const tabTypes = ref(
            slots.default().map((tab) => {
                return tab?.props?.type;
            }),
        );
        const selectedType = ref(tabTypes.value[0]);

        provide("selectedType", selectedType);

        return {
            selectedType,
            tabTypes,
        };
    },
    //   data () {
    //     return {
    //       selectedIndex: 0, // the index of the selected tab,
    //       tabs: []         // all of the tabs
    //     }
    //   },
    //   created () {
    //     this.tabs = this.$children
    //   }
    data() {
        return {
            isFixed: false,
            scrollThreshold: 500, // Adjust this threshold as needed
            // selectedType: null, // Add this if not already defined
            // tabTypes: [], // Add your tab types array here
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            // const tabsContainer = this.$refs.tabsContainer;
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;

            if (scrollPosition > this.scrollThreshold) {
                this.isFixed = true;
                // Adjust styles or add a class for fixed position
            } else {
                this.isFixed = false;
                // Reset styles or remove the class for normal position
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.fixed-tabs {
    position: fixed;
    top: 8%;
    left: 20%;
    right: 0;
    width: 78%;
    z-index: 8;

    /* Add other styles for the fixed position */
}
.tabs {
    width: 100%;
    margin: 0 auto;
    /* background-color: #FFF; */
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: -5%;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 3%);
}

.tabs__header {
    border-radius: 10px 10px 0 0;
    // margin-bottom: -1px;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    background: #fff;
    justify-content: space-evenly;
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    border-bottom: 2.5px solid #2196f3;
}

.tabs__header li {
    width: 100%;
    text-align: center;
    padding: 1.25rem 0px;
    cursor: pointer;
    transition: 0.4s all ease-out;
    // border-bottom: 2px solid;
    // &:first-child{
    //     border-bottom:2px solid #2196f3;
    // }
    // &:nth-child(2){
    //     border-bottom:2px solid #29cc39;
    // }
    // &:nth-child(3){
    //     border-bottom:2px solid #ffcb33;
    // }
    // &:nth-child(4){
    //     border-bottom: 2px solid #33bfff;
    // }
}

.tabs__header li:hover {
    background-color: #f4f7fe;
    font-size: 18px;
    // position: relative;
    // &::after{
    //     content: '';
    //     background: #2196f3;
    //     width: 100%;
    //     height: 2px;
    //     border-radius: 50px;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    // }
}

.tabs__header li.selected {
    &:hover {
        font-size: 20px;
    }

    // &:first-child{
    //     background: #2196f3;
    // }
    // &:nth-child(2){
    //     background: #29cc39;
    // }
    // &:nth-child(3){background: #ffcb33;}
    // &:nth-child(4){background: #33bfff;}
    background-color: #2196f3;
    color: #fff;
    border-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    transform: scale(1.02);
    // border: 1px solid #fff;
    //   border-top: 3px solid #fff;
    //   border-bottom: 1px solid #fff;
    //   transform: translateY(-1rem)
}
</style>
<!-- <AssessementsTabs>
        <assessementTab type="Top Assessements" >
          <Transition name="slide">
          <div class="library-grid-top" v-show="showTab.top">
            <TopAssessement
              v-for="(assess, index) in TopAssessement"
              :key="index"
              :assessement="assess"
              @openPreview="OpenPreview(assess)"
            />
          </div>
        </Transition>
        </assessementTab>
        <assessementTab type="Hard Skills">
          <Transition name="slide">
          <div class="library-grid" v-show="showTab.hard">
            <LibraryCard
              v-for="(assess, index) in hardSkills"
              :key="index"
              :assessement="assess"
              @click="OpenPreview(assess)"
            />
          </div>
        </Transition>
        </assessementTab>
      </AssessementsTabs> -->
