<template>
    <section
        class="flex flex-col-reverse lg:flex-row justify-between items-center gap-4 lg:gap-0 bg-gradient-to-r from-[#00AEF0] via-[#4960F9] to-[#7B2AFF] px-10 pt-20 pb-8 lg:pl-40 lg:pr-10 lg:pt-24 lg:pb-0"
    >
        <div class="flex flex-col justify-between items-center w-full lg:w-1/2">
            <h1 class="w-full text-center lg:text-left text-5xl lg:text-8xl font-extrabold text-white">
                Hiring made
                <span class="typed-text">{{ typeValue }}</span>
                <span class="blinking-cursor text-white">|</span>
                <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
            </h1>
            <p class="w-full text-center lg:text-left text-lg font-normal text-white my-10 lg:my-20">
                GO PLATFORM provides you withthe best tools to screen your candidates so you can make better, faster and easier hiring decisions.
            </p>
            <div class="w-full flex flex-col lg:flex-row justify-between lg:justify-start items-center gap-5 lg:gap-10">
                <button class="w-1/2 h-16 lg:w-44 rounded-md px-2 py-3 text-lg font-semibold text-center text-white bg-[#FF358A] hover:bg-[rgba(255,53,138,70%)]">Get Started</button>
                <button
                    class="w-1/2 h-16 lg:w-44 rounded-md border-2 border-white px-2 py-3 text-lg font-semibold text-center text-slate-700 bg-white flex justify-center items-center flex-row gap-2 hover:bg-transparent hover:text-white"
                >
                    Watch Video <font-awesome-icon :icon="['fas', 'circle-play']" class="w-7 h-7" />
                </button>
            </div>
        </div>
        <img loading="lazy" decoding="async" src="../../assets/Images/woman_productTour.svg" alt="Go platform" class="w-full lg:w-2/5 lg:-mb-10 aspect-auto" />
    </section>
</template>

<script>
export default {
    name: "HeroSection",
    components: {},
    data() {
        return {
            texts: ["effortless", "costless", "efficient"],
            typeValue: "",
            typeStatus: false,
            displayTextArray: ["effortless", "costless", "efficient"],
            typingSpeed: 100,
            erasingSpeed: 100,
            newTextDelay: 2000,
            displayTextArrayIndex: 0,
            charIndex: 0,
        };
    },
    methods: {
        typeText() {
            if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
                if (!this.typeStatus) this.typeStatus = true;
                this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(this.charIndex);
                this.charIndex += 1;
                setTimeout(this.typeText, this.typingSpeed);
            } else {
                this.typeStatus = false;
                setTimeout(this.eraseText, this.newTextDelay);
            }
        },
        eraseText() {
            if (this.charIndex > 0) {
                if (!this.typeStatus) this.typeStatus = true;
                this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(0, this.charIndex - 1);
                this.charIndex -= 1;
                setTimeout(this.eraseText, this.erasingSpeed);
            } else {
                this.typeStatus = false;
                this.displayTextArrayIndex += 1;
                if (this.displayTextArrayIndex >= this.displayTextArray.length) this.displayTextArrayIndex = 0;
                setTimeout(this.typeText, this.typingSpeed + 1000);
            }
        },
    },
    mounted() {
        setTimeout(this.typeText, this.newTextDelay + 200);
    },
};
</script>

<style lang="scss" scoped>
// Cursor blinking CSS Starts...
.blinking-cursor {
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
}
@keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: white;
    }
}
@-moz-keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: white;
    }
}
@-webkit-keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: white;
    }
}
@-ms-keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: white;
    }
}
@-o-keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: white;
    }
}
// Cursor blinking CSS Ends..
</style>
