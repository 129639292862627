<template>
    <section class="flex flex-col justify-between items-center gap-5 py-10">
        <h1 class="text-3xl lg:text-6xl font-bold text-center lg:text-left text-slate-700 w-full lg:px-24 px-8">3. Analyze and decide on the best candidates.</h1>
        <h2 class="text-base lg:text-lg font-normal text-slate-700 text-center lg:text-left w-full lg:px-24">
            Watch the results roll in and discover your strongest candidates with GO PLATFORM’s easy-to-read output reports.
        </h2>
        <div class="flex flex-col justify-between items-center w-full px-8 lg:px-40 lg:gap-16 gap-10">
            <div class="flex flex-row items-center lg:justify-center lg:gap-16 w-full lg:mt-20">
                <button class="w-full h-full lg:w-52 p-5 rounded-md text-sm font-bold text-center" :class="step === 1 ? 'text-white bg-[#FF6969]' : 'text-slate-700 bg-slate-100'" @click="step = 1">
                    Real-time assessment results
                </button>
                <button class="w-full h-full lg:w-52 p-5 rounded-md text-sm font-bold text-center" :class="step === 2 ? 'text-white bg-[#FF6969]' : 'text-slate-700 bg-slate-100'" @click="step = 2">
                    Compare your candidates instantly
                </button>
                <button class="w-full h-full lg:w-52 p-5 rounded-md text-sm font-bold text-center" :class="step === 3 ? 'text-white bg-[#FF6969]' : 'text-slate-700 bg-slate-100'" @click="step = 3">
                    Go in-depth with a candidate review
                </button>
            </div>
            <div v-show="step === 1" class="w-full flex flex-col lg:flex-row gap-4 lg:gap-12">
                <div class="flex flex-col justify-between items-center">
                    <h1 class="text-2xl lg:text-4xl font-semibold text-left w-full text-slate-700">Real-time assessment results</h1>
                    <p class="text-left text-base font-light text-slate-500 my-6">
                        Stay updated with real-time insights into your assessment process. Our platform provides instant access to assessment status and statistics, allowing you to monitor progress
                        effortlessly. Track how many candidates have started or completed the assessment, along with the timestamp of the latest activity. With this real-time visibility, you can stay
                        informed about the status of your assessments and take prompt action as needed. Say goodbye to guesswork and hello to data-driven decision-making with our real-time assessment
                        results feature
                    </p>
                    <a class="w-fit p-3 rounded-md text-white bg-[#FF6969] mr-auto px-5 font-light" href="/">Get Started</a>
                </div>
                <img loading="lazy" decoding="async" src="../../assets/Images/project_setup.png" alt="" class="w-full lg:w-2/5 aspect-auto" />
            </div>
            <div v-show="step === 2" class="w-full flex flex-col lg:flex-row gap-4 lg:gap-12 relative">
                <!-- <img loading="lazy"  decoding="async" src="../../assets/Images/create_step2.svg" alt="" class="w-full lg:w-2/5 aspect-auto"> -->
                <div class="relative w-full h-96 lg:h-80 lg:w-1/2">
                    <div class="w-36 h-44 rounded-xl absolute p-2 bg-[rgba(51,97,255,30%)] flex flex-col left-2 top-0 lg:left-0 z-0">
                        <div class="flex flex-row w-full gap-4 h-1/4">
                            <span class="w-6 h-6 rounded-full bg-white"></span>
                            <div class="w-20 flex flex-col gap-2 h-full">
                                <span class="w-full h-3 rounded-full bg-white"></span>
                                <span class="w-3/4 h-2 rounded-full bg-white"></span>
                            </div>
                        </div>
                        <div class="w-full h-3/4 bg-[rgba(255,255,255,50%)] rounded-xl p-2 justify-center flex flex-col gap-3">
                            <span class="bg-white w-full h-2 rounded-full"></span>
                            <span class="bg-white w-full h-2 rounded-full"></span>
                            <span class="bg-white w-full h-2 rounded-full"></span>
                            <span class="bg-white w-1/2 h-2 rounded-full"></span>
                        </div>
                    </div>
                    <div class="w-36 h-44 rounded-xl absolute p-2 bg-[#FCCDC3] flex flex-col right-28 top-20 lg:right-36 z-10">
                        <div class="flex flex-row w-full gap-4 h-1/4">
                            <span class="w-6 h-6 rounded-full bg-white"></span>
                            <div class="w-20 flex flex-col gap-2 h-full">
                                <span class="w-full h-3 rounded-full bg-white"></span>
                                <span class="w-3/4 h-2 rounded-full bg-white"></span>
                            </div>
                        </div>
                        <div class="w-full h-3/4 bg-[rgba(255,255,255,50%)] rounded-xl p-2 justify-center flex flex-col gap-3">
                            <span class="bg-white w-full h-2 rounded-full"></span>
                            <span class="bg-white w-full h-2 rounded-full"></span>
                            <span class="bg-white w-full h-2 rounded-full"></span>
                            <span class="bg-white w-1/2 h-2 rounded-full"></span>
                        </div>
                    </div>
                    <div class="w-36 h-44 rounded-xl absolute p-2 bg-[rgba(255,53,138,30%)] flex flex-col right-0 bottom-0 z-0">
                        <div class="flex flex-row w-full gap-4 h-1/4">
                            <span class="w-6 h-6 rounded-full bg-white"></span>
                            <div class="w-20 flex flex-col gap-2 h-full">
                                <span class="w-full h-3 rounded-full bg-white"></span>
                                <span class="w-3/4 h-2 rounded-full bg-white"></span>
                            </div>
                        </div>
                        <div class="w-full h-3/4 bg-[rgba(255,255,255,50%)] rounded-xl p-2 justify-center flex flex-col gap-3">
                            <span class="bg-white w-full h-2 rounded-full"></span>
                            <span class="bg-white w-full h-2 rounded-full"></span>
                            <span class="bg-white w-full h-2 rounded-full"></span>
                            <span class="bg-white w-1/2 h-2 rounded-full"></span>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col justify-between items-center w-full">
                    <h1 class="text-2xl lg:text-4xl font-semibold text-left w-full text-slate-700">Compare your candidates instantly</h1>
                    <p class="text-left text-base font-light text-slate-500 my-6">
                        Effortlessly compare the performance of multiple candidates side by side. Our platform enables you to view key metrics and scores for each candidate simultaneously, making it
                        easy to identify top performers and areas for improvement. With instant comparison capabilities, you can quickly gauge which candidates align best with your hiring criteria and
                        make data-driven decisions with confidence.
                    </p>
                    <a class="w-fit p-3 rounded-md text-white bg-[#FF6969] mr-auto px-5 font-light" href="/">Get Started</a>
                </div>
            </div>
            <div v-show="step === 3" class="w-full flex flex-col-reverse lg:flex-row-reverse gap-4 lg:gap-12">
                <img loading="lazy" decoding="async" src="../../assets/Images/analyse_step3.svg" alt="" class="w-full lg:w-2/5 aspect-auto" />
                <div class="flex flex-col justify-between items-center">
                    <h1 class="text-2xl lg:text-4xl font-semibold text-left w-full text-slate-700">Go in-depth with a candidate review</h1>
                    <p class="text-left text-base font-light text-slate-500 my-6">
                        Dive deep into each candidate's assessment results with our comprehensive candidate review feature. Gain valuable insights into their strengths, weaknesses, and overall
                        suitability for the role. Explore detailed breakdowns of their performance across different assessment sections or competencies. With our intuitive interface, conducting a
                        thorough candidate review has never been easier. Equip yourself with the information you need to select the best-fit candidates for your organization.
                    </p>
                    <a class="w-fit p-3 rounded-md text-white bg-[#FF6969] mr-auto px-5 font-light" href="/">Get Started</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "TourSection",
    data() {
        return {
            step: 1,
        };
    },
};
</script>

<style lang="scss" scoped></style>
